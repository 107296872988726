<template>
    <div>
        <a-button
            v-if="showButton"
            size="default"
            class="open_button"
            type="link"
            :loading="loading" 
            @click="openDrawer">
            <i class="fi fi-rr-menu-burger"></i>
        </a-button>
        <ActivityDrawer 
            v-model="visible" 
            @afterVisibleChange="visibleChange">
            <ActivityItem
                v-if="!dropActions && actionLoading"
                key="menu_loader">
                <div class="w-full flex justify-center">
                    <a-spin size="small" />
                </div>
            </ActivityItem>
            <template v-if="dropActions">
                <template v-if="!item.is_auction && dropActions.change_status && showStatus && cStatusFiltered.length">
                    <ActivityItem
                        v-if="statusLoader"
                        key="loader">
                        <a-spin size="small" />
                    </ActivityItem>
                    <template v-else>
                        <ActivityItem
                            v-for="status in cStatusFiltered"
                            :key="status.code"
                            @click="changeStatus(status)">
                            <div v-if="status.color !== 'default'"  class="mob_badge">
                                <a-badge :color="status.color" />
                            </div>
                            {{ status.btn_title ? status.btn_title : status.name }}
                        </ActivityItem>
                    </template>
                </template>
                <ActivityItem
                    v-if="dropActions.add_subtask && item.level < 3" 
                    key="add"
                    @click="addSubtask()">
                    <i class="fi fi-rr-folder-tree mr-2"></i>
                    {{$t('task.add_subtask')}}
                </ActivityItem>
                <ActivityItem
                    v-if="dropActions.share"
                    key="share"
                    @click="share()">
                    <i class="fi fi-rr-share mr-2"></i>
                    {{$t('task.share_to_chat')}}
                </ActivityItem>
                <ActivityItem
                    v-if="dropActions.edit" 
                    key="edit"
                    @click="edit()">
                    <i class="fi fi-rr-edit mr-2"></i>
                    {{$t('task.edit')}}
                </ActivityItem>
                <ActivityItem
                    v-if="dropActions.copy"
                    key="copy"
                    @click="copy()">
                    <i class="fi fi-rr-copy-alt mr-2"></i>
                    {{$t('task.copy')}}
                </ActivityItem>
                <ActivityItem 
                    v-if="dropActions.delete && !item.children_count"
                    key="delete" 
                    @click="deleteTask()">
                    <div class="text-red-500">
                        <i class="fi fi-rr-trash mr-2"></i>
                        {{$t('task.remove')}}
                    </div>
                </ActivityItem>
            </template>
        </ActivityDrawer>
    </div>
</template>

<script>
import { ActivityItem, ActivityDrawer } from '@/components/ActivitySelect'
import mixins from './mixins.js'
export default {
    components: {
        ActivityItem, 
        ActivityDrawer
    },
    mixins: [
        mixins
    ],
    computed: {
        cStatusFiltered() {
            if(this.isAuthor  || (this.isLogistic && this.isOperator))
                return this.filteredList.filter(f => f.code !== this.item.status.code)
            else
                return this.filteredList.filter(f => f.code !== this.item.status.code && !f.is_complete)
        }
    },
    data() {
        return {
            visible: false,
        }
    },
    methods: {
        openDrawer() {
            this.visible = true
        }
    }
}
</script>

<style scoped>
.open_button {
    display: flex;
    justify-content: center;
    align-items: center;

    line-height: 100%;
}
.active_option {
    color: var(--blue);
}
.mob_badge{
    width: 22px;
    height: 22px;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    &::v-deep{
        .ant-badge{
            .ant-badge-status-dot{
                width: 10px;
                height: 10px;
            }
        }
    }
}
</style>